<template>
  <!--
    The view for the Contact Tracing Statistics View
  -->
  <div>
    <div
      v-if="authenticationHasRole('tracyapp')"
      class="contactTracingStatistics"
    >
      <ContactTracingStatistics
        ref="contactTracingStatisctics"
        @reloadAuditLogs="reloadAuditLogs"
      />
      <DatabaseAuditLogList
        ref="databaseAuditLogList"
        :is-tracing-statistic="true"
      />
    </div>
    <div v-else>
      You are not authorized to see this page. Please ask OnsiteSupport Team to authorize you!
    </div>
  </div>
</template>

<script>
import { authenticationMixin } from '@/mixins/authenticationMixin';

export default {
  name: "ContactTracingStatisticView",
  components: {
    DatabaseAuditLogList: () => import('@/components/DatabaseAuditLogs/DatabaseAuditLogsList.vue'),
    ContactTracingStatistics: () => import('@/components/ContactTracing/ContactTracingStatistics.vue')
  },
  mixins: [
    authenticationMixin
  ],
  methods: {
    reloadAuditLogs () {
      this.$refs.databaseAuditLogList.reloadAuditLogs();
    }
  },
  metaInfo () {
    return {
      title: this.$t('contactTracing')
    }
  }
}
</script>
